


















































































































































































































































































































































.onetime-order-page {
  min-height: 100vh;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #000000;

  .custom-container {
    max-width: 1116px;
    width: 100%;
    margin: 0 auto;

    .orderpage-step {
      .order-step-2 {
        max-width: 1116px;
        width: 100%;
        margin: 0 auto;
        border-radius: 0.25rem;
        box-shadow: 0 0.05rem 0.01rem rgb(75 75 90 / 8%);
        background-color: #fff;
        margin-bottom: 24px;
        padding: 50px 30px;
        text-align: center;
      }
    }
  }

  #order-header-section {
    background: #f7f7f7;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    position: relative;

    #order-header-image {
      padding: 20px 0 20px 20px;
      width: 245px;
      max-width: 100%;
    }

    #order-header-text {
      flex: 1;
      padding: 20px 30px;
    }

    #order-header-image2 {
      padding: 0 50px 20px 0;
      width: 190px;
      max-width: 100%;
    }

    @media all and (max-width: 991px) {
      display: block;

      #order-header-image {
        padding: 20px 20px 0 20px;
        width: 265px;
      }

      #order-header-text {
        padding: 20px;
      }

      #order-header-image2 {
        position: absolute;
        top: 0;
        right: 20px;
        padding: 0;
        width: 80px;
      }
    }
  }

  #order-content-section {
    width: 100%;
    display: flex;

    #order-form-section {
      padding: 30px 40px;
      flex: 0 0 60%;
      width: 60%;

      input[type=text] {
        height: 45px;
      }

      input[type=tel] {
        height: 43px;
      }

      .authentication-form {
        >ul {
          list-style: none;
          padding: 0;

          li {
            >label {
              display: flex;
              align-items: center;
              margin: 0;
              margin-bottom: 1rem;
              padding: 0;
              width: 100%;
              border: 2px solid rgba(0, 0, 0, 0.1);
              cursor: pointer;
              border-radius: 4px;

              >input[type=radio] {
                margin-left: 10px;
                margin-right: 10px;
              }

              >span {
                font-size: 14px;
                color: black;
                font-weight: 400;
              }

              >span:last-child {
                font-size: 18px;
                color: black;
                font-weight: 700;
                border-left: 1px solid #e5e5e5;
                background-color: #f5f5f5;
                margin-left: auto;
                margin-right: 0;
                width: 135px;
                padding: 15px;
              }
            }
          }
        }

        .cardForm {
          .form-control {
            height: calc(1.5rem + 1.5em);
            padding: 0.75rem 0.75rem;
          }
        }

        .text-center {
          >img {
            margin: 0;
            padding: 0;
          }
        }

        #order-summary-section {
          .total-div {
            display: flex;
            align-items: center;
            background-color: #e9e9e9;
            padding: 15px;

            >label,
            >span {
              color: black;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              padding: 0;
            }

            >span {
              margin-left: auto;
              margin-right: 0;
            }
          }

          .desc-div {
            font-size: 13px;
          }
        }

        button[type=submit] {
          min-height: 50px;
        }
      }
    }

    #order-details-section {
      flex: 0 0 40%;
      width: 40%;
      height: 100%;
      background: #F8FFE2;
      display: flex;
      flex-direction: column;

      #order-details-desc {
        width: 100%;
        flex: 1;
        padding: 30px 20px;
      }

      #order-details-images {
        width: 100%;
        background-color: rgb(249, 249, 249);

        img.fr-dib {
          display: block;
          float: none;
          vertical-align: top;
        }

        p {
          margin-bottom: 0;
        }

        >ul {
          width: 100%;
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;

          >li {
            flex: 0 0 33.33%;
            width: 33.33%;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }
      }
    }

    @media all and (max-width: 991px) {
      flex-direction: column-reverse;

      #order-form-section {
        flex: 0 0 100%;
        width: 100%;
        padding: 20px;
      }

      #order-details-section {
        flex: 0 0 100%;
        width: 100%;
      }
    }
  }

  #order-copyright-text {
    text-align: center;
    color: #a1a1a1;
    font-size: 13px;
  }

  @media all and (max-width: 767px) {

    code,
    span,
    p,
    label,
    ul li {
      font-size: 15px !important;
    }

    .h1,
    h1,
    .h1 *,
    h1 * {
      font-size: 28px !important;
    }

    .h2,
    h2,
    .h2 *,
    h2 * {
      font-size: 26px !important;
    }

    .h3,
    h3,
    .h3 *,
    h3 * {
      font-size: 22px !important;
    }

    .h4,
    h4,
    .h4 *,
    h4 * {
      font-size: 20px !important;
    }

    .h5,
    h5,
    .h5 *,
    h5 * {
      font-size: 17px !important;
    }

    .h6,
    h6,
    .h6 *,
    h6 * {
      font-size: 15px !important;
    }
  }
}